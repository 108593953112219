/*************************
*  Tooltip body
*************************/

.tooltip {
    pointer-events: none;
    z-index: var(--z-modal);
    padding: var(--spacing-3);
    font: var(--font-text-small);
    background: var(--bg-inverted-page-color);
    border-radius: var(--border-overlay-radius);
    color: var(--text-inverted-color);
    box-shadow: var(--elevation-lvl-1);
    max-width: calc(var(--readable-max-width) / 2);

    transition: transform 0.2s ease-in-out;
    @media (prefers-reduced-motion) {
        transition: none;
    }
}

/*************************
* Arrow
*************************/
:root {
    /*  Make sure the arrow size is odd. This will help pixel precision. */
    --arrow-size: calc(var(--spacing-2) - 1);
}

.arrow {
    pointer-events: none;
    z-index: -1;

    /*  Intentional: We want this to be double the border. */
    width: 13px;
    height: 13px;

    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: 7px solid transparent;
    }

    /*  Arrow directions */

    [data-popup-arrow-placement^='top'] > & {
        top: 100%;
        &::before {
            border-top-color: var(--color-container-background-complementary);
        }
    }

    [data-popup-arrow-placement^='bottom'] > & {
        bottom: 100%;
        &::before {
            border-bottom-color: var(--color-container-background-complementary);
        }
    }

    [data-popup-arrow-placement^='right'] > & {
        right: 100%;
        &::before {
            border-right-color: var(--color-container-background-complementary);
        }
    }

    [data-popup-arrow-placement^='left'] > & {
        left: 100%;
        &::before {
            border-left-color: var(--color-container-background-complementary);
        }
    }
}

:global(.mcds-redesign-organisms) {
    .tooltip {
        pointer-events: none;
        z-index: var(--z-modal);

        transition: transform 0.2s ease-in-out;

        min-width: calc(var(--base-unit) * 12);
        max-width: calc(var(--base-unit) * 50);
        padding: var(--space-container-padding-xx-small);

        background-color: var(--color-container-background-complementary);
        color: var(--color-text-complementary);

        font-size: var(--font-size-component-x-small);
        font-weight: var(--font-weight-component);
        text-align: center;
        line-height: var(--line-height-component);

        border-radius: var(--radius-action);
        box-shadow: 0 1px 4px 0 var(--color-shadow);

        @media (prefers-reduced-motion) {
            transition: none;
        }
    }

    .arrow {
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;

        pointer-events: none;
        z-index: -1;

        [data-popup-arrow-placement^='top'] > & {
            top: 100%;
            &::before {
                border-top-color: var(--color-container-background-complementary);
            }
        }

        [data-popup-arrow-placement^='bottom'] > & {
            bottom: 100%;
            &::before {
                border-bottom-color: var(--color-container-background-complementary);
            }
        }

        [data-popup-arrow-placement^='right'] > & {
            right: 100%;
            &::before {
                border-right-color: var(--color-container-background-complementary);
            }
        }

        [data-popup-arrow-placement^='left'] > & {
            left: 100%;
            &::before {
                border-left-color: var(--color-container-background-complementary);
            }
        }
    }

}
