.listbox {
    background: var(--bg-lvl-1-color);
    box-shadow: var(--elevation-lvl-1);
}

.options {
    max-width: var(--readable-max-width);
    max-height: 400px;
    overflow: auto;
}

:not(.optgroupWrapper) + .optgroupWrapper {
    border-top: 1px solid var(--border-light-color);
}

.optgroupWrapper:not(:last-child) {
    border-bottom: 1px solid var(--border-light-color);
    padding-bottom: var(--spacing-2);
}

.optgroup {
    color: var(--text-secondary-color);
    font-size: var(--font-text-small);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    padding: var(--spacing-4) var(--spacing-5) var(--spacing-3);
    display: flex;
    word-break: break-word;
    position: sticky;
    top: 0;
    background-color: var(--bg-lvl-1-color);
}

.callToAction {
    border-top: 1px solid var(--border-light-color);
    padding: calc(var(--base-unit) * 2.5) calc(var(--base-unit) * 4);

    a {
        font-weight: var(--font-weight-bold);
        color: var(--interactive-color);
        &:hover,
        &:focus {
            color: var(--interactive-hover-color);
            text-decoration: underline;
        }
    }
}

:global(.mcds-redesign-molecules) {
    .listbox {
        border-radius: var(--radius-small);
        border: 1px solid var(--color-container-border-primary);
        background: var(--color-container-background-primary);

        box-shadow: var(--elevation-lvl-2);
        overflow: hidden;
    }

    .options {
        max-height: calc(var(--base-unit) * 90);
    }

    .options,
    .optgroupWrapper {
        width: 100%;
        min-width: calc(var(--base-unit) * 40);
        flex-direction: column;
        align-items: flex-start;
    }

    :not(.optgroupWrapper) + .optgroupWrapper,
    .optgroupWrapper:not(:last-child) {
        border-bottom: 1px solid var(--color-divider-tertiary);
    }

    .optgroup {
        padding: var(--space-container-padding-x-small) var(--space-container-padding-xx-small) var(--space-container-padding-xx-small) var(--space-container-padding-xx-small);
        gap: var(--space-container-padding-xx-small);
        align-self: stretch;
        color: var(--color-text-tertiary);
        font-family: var(--font-family-component);
        font-size: var(--font-size-component-x-small);
        font-style: normal;
        font-weight: var(--font-weight-component-bold);
        line-height: var(--line-height-component);
        border-radius: var(--radius-small);
    }
    .callToAction {
        border-top: none;
        padding: 0;
        color: var(--color-text-accent);

        &:hover {
            background-color: var(--color-action-standard-subtle-hover);
        }
        &:active {
            background: var(--color-action-standard-subtle-active);
        }
        &:focus {
            border: 2px solid var(--focus-indicator);
            background: var(--color-action-standard-subtle-focus);
        }

        a {
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
        .callToActionButton {
            display: flex;
            align-items: center;
            padding: var(--space-component-inline-padding-medium);
            line-height: var(--line-height-body);
            gap: var(--space-row-gap-x-small);
            font-family: var(--font-family-component);
            font-size: var(--font-size-component-small);
            font-weight: var(--font-weight-component);
            line-height: var(--line-height-component);
            border-radius: 0 0 var(--radius-small) var(--radius-small);

            &:focus-visible {
                background-color: var(--color-action-passive-subtle-focus);
                outline: 2px solid var(--color-ui-primary);
                color: var(--color-link-text-focus);
                outline-offset: -2px;
            }

        }
        &.callToActionDisabled {
            color: var(--color-text-disabled);
            pointer-events: none;
            &:focus-visible {
                background-color: none;
                outline: 0;

            }
        }
    }
}

:global(.mcds-redesign-organisms) .searchInput {
    padding: var(--space-component-inline-padding-medium) var(--space-container-padding-xx-small);
}
